@import 'globals/shared/bootstrap/variables.scss';
/***** Breadcrumb-Style *****/
.breadcrumb-item {
	font-size: $h6-font-size;

	a {
		color: $css-var-gray70;
		text-decoration: none;
	}

	span {
		color: $css-var-gray70;
		text-decoration: none;
	}
}

.breadcrumb-item:hover {
	a {
		text-decoration: underline;
	}
}

.breadcrumb-item.active:hover {
	span {
		cursor: text;
	}
}

.dropdown-toggle {
	color: $css-var-gray90 !important;
}

.limit-width-15 {
	max-width: 15rem;
}

input[type='search']::-webkit-search-cancel-button {
	-webkit-appearance: none;
	height: 1.5rem;
	width: 1.5rem;
	background: url('../assets/images/icons/navigate_cross_g.svg') no-repeat;
}
