.form-floating > label {
	display: inline-flex;
	align-items: center;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
	background-color: $topmenu-bg;
	padding: 0 0.1rem;
	height: 1em;
}

.form-floating > .form-control:placeholder-shown:not(:focus)[placeholder]:not([placeholder=' ']) ~ label {
	opacity: $form-floating-label-opacity;
	transform: $form-floating-label-transform;
	background-color: $topmenu-bg;
	padding: $form-floating-input-padding-t 0.1rem $form-floating-input-padding-b;
}
