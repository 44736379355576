// Here you can hard overwrite bootstrap variables
// please prefer using variables-custom.scss

/***** sizing and spacing *****/
$border-size: 1px;
$border-radius: 3px;

$control-box-shadow-size: 0px 0px 0px 1px;

$menu-padding: 8px;

$control-padding: 5px;

/***** other *****/
$animation-times: 0.2s;
$animation-function: ease-in-out;

/***** Floating Labels ******/
$form-floating-height: $input-height;
$form-floating-line-height: $input-line-height;
$form-floating-padding-x: $input-padding-x;
$form-floating-padding-y: $input-padding-y;
$form-floating-input-padding-t: 0;
$form-floating-input-padding-b: 0;
$form-floating-label-opacity: 1;
$form-floating-label-transform: scale(0.65) translateY(-0.6rem) translateX(1rem);
$form-floating-transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out, padding 0.1s ease-in-out,
	height 0.1s ease-in-out, background-color 0.1s ease-in-out;

/***** Table ******/
$table-hover-bg: $light;
