@import 'bootstrap/scss/alert';

.alert {
	border-radius: $border-radius;
}

.alert .close {
	outline: none;
	box-shadow: none;
	border: none;
}
