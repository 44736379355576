@use 'sass:math';

@import 'bootstrap/scss/mixins';

$search-help-sizes: (
	lg: 150px,
	xl: 200px,
);

@each $breakpoint, $width in $search-help-sizes {
	@include media-breakpoint-up($breakpoint) {
		.eva-search-help-width {
			width: $width;
		}

		.eva-help-width,
		.eva-search-width {
			width: math.div($width, 2);
		}
	}
}
