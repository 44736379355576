h1 {
	font-weight: bold;
	margin-bottom: 1rem;
}

h2 {
	font-weight: bold;
	margin-top: 1.2rem;
}

h3 {
	font-weight: bold;
}

@import 'bootstrap/scss/type';
