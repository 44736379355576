@keyframes fadeInLeft {
	0% {
		opacity: 0;
		-moz-transform: translateX(-20px);
		-ms-transform: translateX(-20px);
		-o-transform: translateX(-20px);
		-webkit-transform: translateX(-20px);
		transform: translateX(-20px);
	}
	100% {
		opacity: 1;
		-moz-transform: translateX(0px);
		-ms-transform: translateX(0px);
		-o-transform: translateX(0px);
		-webkit-transform: translateX(0px);
		transform: translateX(0px);
	}
}

@-webkit-keyframes fadeInLeft {
	0% {
		opacity: 0;
		-moz-transform: translateX(-20px);
		-ms-transform: translateX(-20px);
		-o-transform: translateX(-20px);
		-webkit-transform: translateX(-20px);
		transform: translateX(-20px);
	}
	100% {
		opacity: 1;
		-moz-transform: translateX(0px);
		-ms-transform: translateX(0px);
		-o-transform: translateX(0px);
		-webkit-transform: translateX(0px);
		transform: translateX(0px);
	}
}

@keyframes fadeInLeftReverse {
	0% {
		opacity: 1;
		-moz-transform: translateX(0px);
		-ms-transform: translateX(0px);
		-o-transform: translateX(0px);
		-webkit-transform: translateX(0px);
		transform: translateX(0px);
	}
	100% {
		opacity: 0;
		-moz-transform: translateX(-20px);
		-ms-transform: translateX(-20px);
		-o-transform: translateX(-20px);
		-webkit-transform: translateX(-20px);
		transform: translateX(-20px);
	}
}

@-webkit-keyframes fadeInLeftReverse {
	0% {
		opacity: 1;
		-moz-transform: translateX(0px);
		-ms-transform: translateX(0px);
		-o-transform: translateX(0px);
		-webkit-transform: translateX(0px);
		transform: translateX(0px);
	}
	100% {
		opacity: 0;
		-moz-transform: translateX(-20px);
		-ms-transform: translateX(-20px);
		-o-transform: translateX(-20px);
		-webkit-transform: translateX(-20px);
		transform: translateX(-20px);
	}
}

.fade-in-left {
	-o-animation: fadeInLeft 0.5s;
	-moz-animation: fadeInLeft 0.5s;
	-webkit-animation: fadeInLeft 0.5s;
	animation: fadeInLeft 0.5s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
}

.fade-in-left-reverse {
	-o-animation: fadeInLeftReverse 0.5s;
	-moz-animation: fadeInLeftReverse 0.5s;
	-webkit-animation: fadeInLeftReverse 0.5s;
	animation: fadeInLeftReverse 0.5s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
}
