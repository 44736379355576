@import 'bootstrap/scss/forms';

/***** Textbox *****/
.form-control {
	color: $floating-label-color;

	@mixin form-control-opacity($opacity) {
		opacity: $opacity;

		& ~ .form-label-wrapper {
			color: rgba($css-var-body-color-rgb, $opacity);
		}
	}

	&:disabled {
		@include form-control-opacity(0.65);
	}

	&:read-only {
		@include form-control-opacity(0.75);
	}

	&:focus:not(.is-invalid):not(:read-only) {
		~ .description,
		~ .form-label-wrapper:not(.form-label-inside) {
			color: $css-var-primary;
		}
	}
}

.form-control[type='number']::-webkit-inner-spin-button,
.form-control[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.form-control[type='number'] {
	-moz-appearance: textfield;
}

.form-label-wrapper .description {
	transition: color $animation-function $animation-times;
}

.form-control:not(:disabled).is-invalid ~ .description {
	color: $red;
}

.form-control:not(:disabled).is-invalid {
	border-color: $red;
	color: $red;
	background-image: none;
	-webkit-text-fill-color: $red;
}

.form-control:focus:not(.is-invalid):not(:read-only) {
	&:-webkit-autofill {
		box-shadow: 0 0 0 #{add($input-height, 10px)} $topmenu-bg inset, $control-box-shadow-size $css-var-primary;
	}
	&:not(:-webkit-autofill) {
		box-shadow: $control-box-shadow-size $css-var-primary;
	}

	border-color: $css-var-primary;
}

.form-control:not(:disabled):focus.is-invalid:not(:read-only) {
	box-shadow: $control-box-shadow-size $red;
	border-color: $red;
}

.form-control:not(:disabled):focus.is-invalid:read-only {
	border-color: $red;
}

.invalid-feedback {
	color: $red;
	display: block;
}

.input-group-prepend ~ div > .form-control {
	@include border-start-radius(0);
}

.form-label-wrapper {
	position: absolute;
	transition: all $animation-function $animation-times;
	cursor: text;
	pointer-events: none;
}

:not(input:-webkit-autofill) ~ .form-label-wrapper:not(.form-label-inside) {
	font-size: $h6-font-size;
}

input:-webkit-autofill ~ .form-label-wrapper {
	font-size: $h6-font-size;
	z-index: 999;
}

input:-webkit-autofill ~ .form-label-wrapper .form-label:before,
:not(input:-webkit-autofill) ~ .form-label-wrapper:not(.form-label-inside) .form-label:before {
	content: '';
	background: $floating-label-bg;
	position: absolute;
	height: 4px;
	left: -5px;
	right: -5px;
	bottom: 7px;
	z-index: -1;
}

:not(input:-webkit-autofill) ~ .form-label-inside {
	background: none;
	font-size: $input-font-size;
	border-radius: $border-radius;
}

.form-control.is-invalid:not(:disabled) ~ .form-label-wrapper {
	color: $red;
}

.form-label-wrapper.is-invalid {
	color: $red;
}

.form-control {
	border-width: $border-width !important;
}

.form-label-wrapper {
	margin: 0 !important;
}

:not(input:-webkit-autofill) ~ .form-label-wrapper:not(.form-label-inside) {
	top: 0;
	transform: translateY(-50%);
	padding: 0 calc(#{$control-padding} / 2) 0 calc(#{$control-padding} / 2);
}

input:-webkit-autofill ~ .form-label-wrapper {
	top: 0;
	transform: translateY(-50%);
	padding: 0 calc(#{$control-padding} / 2) 0 calc(#{$control-padding} / 2);
}

:not(input:-webkit-autofill) ~ .form-label-inside {
	top: 0;
	bottom: 0;
}

/***** Checkbox & Radio Button *****/

//general box style
.form-check-input {
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	-o-appearance: none;
	appearance: none;
	position: relative;
	min-width: 15px;
	min-height: 15px;
	transition: all 0.15s ease-out 0s;
	cursor: pointer;
	display: inline-block;
	outline: none;
	margin: 0 $eva-form-check-label-distance 0 -2rem;
	box-shadow: none !important;
	border: 2px solid;
}

.form-check-input.is-invalid:not(:disabled) {
	border-color: $red !important;
}

.form-check-input.is-invalid:not(:disabled) ~ .form-check-label {
	color: $red !important;
}

.form-check {
	display: flex;
	align-items: flex-start;
	line-height: 1em;
	margin-bottom: 0;

	&.form-check-alone {
		padding-left: 0;
		min-height: 0;

		.form-check-input {
			margin: 0;
		}
	}
}

//extra style for radio and checkbox
.form-check-input[type='checkbox']:not([role='switch']) {
	border-radius: 2px !important;

	&:checked {
		color: $primary-contrast-color;
	}
	&:not(:checked) {
		color: $css-var-body-color;
	}
}

.form-check-input[type='radio'] {
	border-radius: 50% !important;
	color: $css-var-body-color;
}

// checkbox as switch
.form-check.form-switch {
	display: inherit;
}

.form-check-input[role='switch']:not(:disabled):hover:after {
	width: 46px;
	left: 15px;
	border-radius: 14px;
}
.form-check-input[role='switch']:active:after {
	left: 15px;
	width: 80px !important;
	border-radius: 40px;
}

//general background circle
.form-check-input:after {
	content: '' !important;
	display: block !important;
	position: relative !important;
	height: 0;
	width: 0;
	transform: translate(-50%, -50%);
	left: 5px;
	top: 5px;
	opacity: 0.35;
	border-radius: 50%;
	transition: all ease-out 0.2s;
}

.form-check-input:checked:not(.is-invalid):after {
	background: $css-var-primary !important;
}

.form-check-input.is-invalid:checked:after {
	background: $red !important;
}

//trigger circle hover animation
.form-check-input:not(:disabled):hover:after {
	height: 26px;
	width: 26px;
	right: 8px !important;
	bottom: 8px !important;
}

//trigger circle active animation
.form-check-input:active:after {
	height: 50px !important;
	width: 50px !important;
	opacity: 0 !important;
}

//general style on checked
.form-check-input:checked::before {
	position: absolute !important;
	display: inline-block !important;
	text-align: center !important;
	line-height: 15px !important;
	font-size: 12px !important;
}

.form-check-input:not(.is-invalid):checked::before {
	background: $css-var-primary !important;
}

.form-check-input.is-invalid:not(:disabled):checked::before {
	background: $red !important;
}

//extra style specific for checkbox and radio
.form-check-input[type='checkbox']:not([role='switch']):checked::before {
	content: '✔' !important;
	height: 15px !important;
	width: 15px !important;
	top: -2px !important;
	left: -2px !important;
}

.form-check-input[type='radio']:checked::before {
	height: 11px !important;
	width: 11px !important;
	content: '' !important;
	top: 0 !important;
	left: 0 !important;
	border-radius: 50% !important;
	border: 2px solid $topmenu-bg !important;
}

.form-check-input[type='radio'].is-invalid:checked {
	border-color: $red !important;
}

/***** Select-Box *****/

.form-select {
	option:checked {
		background-color: $css-var-gray40;
	}

	&:not(:disabled) option:hover {
		font-weight: bold;
	}
}

/******Typeahead*******/
.typeahead-popover .form-check {
	min-height: 0;
}
