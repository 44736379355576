// Here you can change the defaults of bootstrap's variables

// theme-colors
$primary: #095a5a;
$secondary: #458383;
$success: #008800;
$warning: #e4b000;
$danger: #c80045;
$light: #e0ebeb;
$muted: rgba(224, 235, 235, 0.35);

// color-variables
$blue: #005ac4;
$tourquise: #006583;
$green: #098b59;
$yellow: #e0cd53;
$red: #b16868;
$plum: #0038a3;

// region gray colors

// Changes here should be matched with app.init.ts and old world
// gray switching implementation

$white: #fff;
$gray2: #fafafa;
$gray20: #cccccc;
$gray40: #cccccc;
$gray70: #4c4c4c;
$gray75: #4c4c4c;
$gray80: #333333;
$gray85: #333333;
$gray90: #333333;
$black: #000;

$cgray: #0000000f;

// Bootstrap gray
$gray-100: $gray2;
$gray-200: $gray20;
$gray-300: $gray20;
$gray-400: $gray40;
$gray-500: $gray70;
$gray-600: $gray75;
$gray-700: $gray80;
$gray-800: $gray85;
$gray-900: $gray90;

// endregion gray colors

// Fallbacks if css-properties fail
$body-bg: $gray2;
$body-color: #191919; // gray90
$link-color: $body-color;
$link-hover-color: $primary;
$text-muted: #898989;
$input-bg: $white;

/***** Popover *****/
$popover-arrow-outer-color: transparent;

// Links
$link-decoration: none;

// region Forms

$input-border-radius: 0.25rem;

$input-box-shadow: none;
$input-focus-box-shadow: none;

$input-bg: transparent;
$input-focus-bg: inherit;
$input-focus-border-color: inherit;
$input-disabled-bg: inherit;

$floating-label-bg: var(--eva-floating-label-bg);
$floating-label-color: var(--eva-floating-label-color);

$css-var-dropdown-bg: var(--eva-dropdown-bg);
$css-var-dropdown-color: var(--eva-dropdown-color);

$form-check-input-disabled-opacity: 0.65;
$form-check-padding-start: 2em;
$form-check-input-width: 15px;

$form-select-disabled-bg: transparent;

// endregion

// region Tables

$table-bg: inherit;
$table-accent-bg: inherit;
$table-striped-color: inherit;
$table-striped-bg: inherit;
$table-active-color: inherit;
$table-active-bg: inherit;
$table-hover-color: inherit;
$table-border-width: 0;

// endregion Tables

// region buttons

$btn-padding-y: 3px;
$btn-padding-x: 6px;
$btn-focus-box-shadow: none;
$btn-disabled-opacity: 0.45;

$font-size-base: 1.08333rem; // 17.3333px

$input-font-size: 11.5pt;
$dropdown-font-size: $input-font-size;
$btn-font-size: $input-font-size;
$badge-font-size: 10pt;
$form-select-font-size: $input-font-size;

// endregion buttons

// region _navbar.scss

$navbar-toggler-padding-y: $btn-padding-y;
$navbar-toggler-padding-x: $btn-padding-x;

// endregion _navbar.scss

// region _tooltip.scss

$tooltip-opacity: 1;

// endregion _tooltip.scss

$h1-font-size: 1.65rem;
$h2-font-size: 1.4rem;
$h3-font-size: 1.2rem;
$h4-font-size: 1rem;
$h5-font-size: 0.9rem;
$h6-font-size: 0.75rem;

// region shared with restyling

$eva-form-check-label-distance: 0.5rem;

// endregion shared with restyling
