@import '../../../node_modules/bootstrap/scss/nav';

/***** Menu *****/
.eva-sidebar-bg-color {
	background-color: $white; // Fallback if css variables are disabled
	background-color: $sidebar-bg;
}

.eva-sidebar-color {
	color: $sidemenu-item-color;
}

.evainsights-icon-row {
	background-color: $white; // Fallback if css variables are disabled
	background-color: $sidebar-bg;
	&:hover {
		background-color: $css-var-light;
		cursor: pointer;
	}
}

.eva-submenu-heading {
	background-color: $sidemenu-heading-bg;
	color: $sidemenu-heading-color;
}

.nav-pills {
	width: 100%;
	margin: 0;
	padding: $menu-padding;

	.nav-link {
		padding: $menu-padding;
		width: 100%;
		transition: all $animation-function $animation-times;
		border-radius: 0;

		&.active {
			background-color: $light;
			background-color: $sidemenu-item-active-bg;
			color: $primary;
			color: $sidemenu-item-active-color;
		}

		&:not(.active) {
			&:hover {
				box-shadow: 1px 1px 3px 1px $css-var-gray20;

				background-color: $white;
				background-color: $sidebar-bg;
				color: $primary;
				color: $sidemenu-item-hover-color;
			}

			&:not(:hover) {
				color: $gray85;
				color: $sidemenu-item-color;
			}
		}
	}

	.nav-icon {
		max-width: 25px;
		max-height: 25px;
	}
}

/***** Tabs *****/
.eva-topmenu-bg-color {
	background-color: $white !important; // Fallback if css variables are disabled
	background-color: $topmenu-bg !important;
}

.eva-topmenu-color {
	color: $gray70;
	color: $topmenu-item-color;
}

.eva-topmenu-logo-bg-color {
	background-color: $topmenu-logo-bg;
}

.nav-tabs .nav-link {
	border: none;
	transition: color ease-out 0.3s;
	font-weight: bold;
	font-size: 0.7em;
	position: relative;

	color: $gray70;
	color: $topmenu-item-color;
}

.nav-tabs .nav-link.active {
	border: none;
	background: none;

	color: $primary;
	color: $topmenu-item-active-color;
}

.nav-tabs .nav-link::before {
	bottom: -1px;
	content: '';
	display: block;
	height: 2px;
	left: 50%;
	opacity: 0;
	position: absolute;
	-webkit-transform: translate(-50%, -5px);
	transform: translate(-50%, -5px);
	width: 100%;
	transition: all $animation-function $animation-times;
}

.nav-tabs .nav-link.active::before {
	opacity: 1;
	transform: translate(-50%, 0px);

	background-color: $primary;
	background-color: $topmenu-item-active-color;
}

.nav-tabs .nav-link:hover:not(.active)::before {
	background-color: $css-var-gray20;
	opacity: 1;
	transform: translate(-50%, 0px);
}

/***** Nav *****/
.nav > a.active {
	background: $css-var-light;
	border-radius: 50%;
}

.navbar-toggler:focus {
	box-shadow: none !important;
}
