@import 'bootstrap/scss/pagination';

.pagination {
	margin: 0;
}

.page-link {
	border: 0;
	border-radius: 50% !important;
	color: $black;
	background: none;
	width: 40px;
	height: 40px;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.page-item:not(.active) .page-link:hover {
	color: $black;
}
a.page-link:not(:hover) {
	color: inherit;
}
.page-link:focus {
	outline: none;
	box-shadow: none;
	border: none;
}

.page-item.active .page-link {
	background: $css-var-primary;
	color: $primary-contrast-color;
}

.pagination-button {
	border: $border-size solid $border-color;
	border-radius: $border-radius;
	padding: 5px 8px 5px 8px;
	color: $css-var-primary;
	font-weight: bold;
}

.pagination-button:disabled {
	color: $css-var-gray80;
}
