.c-hover-gray:hover {
	transition: background $animation-function $animation-times;
	background: $cgray;
}

.c-bg-white {
	background: white;
}

.c-bg {
	background: #fafafa;
}

.c-active-white.active {
	background: $white;
}

.c-gray-scale-100 {
	filter: grayscale(100%);
}
