@import '../../../node_modules/bootstrap/scss/card';

.card {
	box-shadow: $box-shadow-sm;
	border: 0;
	color: $css-var-card-color;
	--eva-floating-label-bg: #{$css-var-card-bg};
	--eva-floating-label-color: #{$css-var-card-color};
	--eva-dropdown-bg: #{$css-var-card-bg};
	--eva-dropdown-color: #{$css-var-card-color};
}

.card-header {
	background: $card-bg;
}
