@import 'globals/evainsights/bootstrap/variables';
@import 'styles/breadcrumb';
@import 'styles/table';

/***** Fonts *****/

@font-face {
	font-family: 'IBM Plex Sans';
	src: url('assets/fonts/IBM Plex Sans/IBMPlexSans-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'IBM Plex Sans';
	src: url('assets/fonts/IBM Plex Sans/IBMPlexSans-SemiBold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'IBM Plex Sans';
	src: url('assets/fonts/IBM Plex Sans/IBMPlexSans-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'IBM Plex Sans';
	src: url('assets/fonts/IBM Plex Sans/IBMPlexSans-SemiBoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

html,
body,
evainsights-root {
	height: 100%;
}

html {
	position: relative;
}

body {
	background: $css-var-gray2;
}

body,
button {
	font-family: 'IBM Plex Sans', sans-serif !important;
	letter-spacing: 0.01em;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/***** Popover-Style *****/
.popover {
	z-index: 1;
	.popover-body {
		direction: ltr;
		max-height: inherit;
		overflow-y: auto;
	}
}

/***** Scrollbar-Style *****/
*::-webkit-scrollbar {
	width: 11px;
}

* {
	scrollbar-width: thin;
	scrollbar-color: $dark $light;
}

*::-webkit-scrollbar-track {
	background: $light;
}

*::-webkit-scrollbar-thumb {
	background-color: $dark;
	border-radius: 6px;
	border: 3px solid $light;
}

/***** SideMenu-InnerContent-Style *****/
.side-menu-inner-content-padding {
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
}

.side-menu-inner-content-title {
	font-size: $h4-font-size;
	color: $css-var-gray70;
	text-align: center;
}

/***** Report Creator *****/
.span-creator {
	display: block;
	margin-left: 0.125rem;
}
/***** Custom Sizes *****/
.c-margin-top-m025rem {
	margin-top: -0.25rem;
}

@mixin size-utils($value, $unit, $unit-name: null) {
	$un: $unit-name or $unit;

	.c-w-#{$value}#{$un} {
		width: #{$value}#{$unit};
	}

	.c-h-#{$value}#{$un} {
		height: #{$value}#{$unit};
	}

	.c-min-w-#{$value}#{$un} {
		min-width: #{$value}#{$unit};
	}

	.c-min-h-#{$value}#{$un} {
		min-height: #{$value}#{$unit};
	}

	.c-max-w-#{$value}#{$un} {
		max-width: #{$value}#{$unit};
	}

	.c-max-h-#{$value}#{$un} {
		max-height: #{$value}#{$unit};
	}
}

@for $val from 1 through 25 {
	@include size-utils($val, 'rem');
}

.c-max-h-100vh-20rem {
	max-height: calc(100vh - 20rem);
}

@include size-utils(30, 'rem');
