@import '../mixins/hide-scrollbar';

@import 'sizes';
@import 'distances';
@import 'colors';

.c-z-index-unset {
	z-index: unset !important;
}

.c-object-fit-contain {
	object-fit: contain;
}

.c-resize-none {
	resize: none;
}

.c-resize-horizontal {
	resize: horizontal;
	overflow: hidden;
}

.c-direction-rtl {
	direction: rtl;
}

.c-user-select-none {
	user-select: none;
}

.c-minw-content {
	width: fit-content;
	min-width: 100% !important;
}

.c-hover-td-none:hover {
	td {
		box-shadow: none;
	}
}

.c-max-h-10 {
	max-height: 10rem;
}

.c-max-h-15 {
	max-height: 15rem;
}

.c-max-h-25 {
	max-height: 25rem;
}

.c-min-h-100 {
	min-height: 100%;
}

.c-content-min-width {
	min-width: 200px;
}

.c-flex-1 {
	flex: 1;
}

.table-hover > tbody > .c-no-hover:hover > * {
	--bs-table-accent-bg: var(--bs-table-bg);
	color: var(--bs-table-color);
}

.animate-hidden.ng-animating {
	overflow: hidden;
}

.c-table-fixed {
	table-layout: fixed;
}

.c-pre-wrap {
	white-space: pre-wrap;
}

.c-nowrap {
	white-space: nowrap;
}

.c-word-wrap-break {
	word-wrap: break-word;
}

.c-footer {
	padding: 7px 7px 7px 0;
	letter-spacing: normal;

	a {
		color: $css-var-text-muted;

		&:hover {
			text-decoration: underline;
		}
	}
}

.c-overflow-x-auto {
	overflow-x: auto;
}

.c-overflow-y-auto {
	overflow-y: auto;
}

.c-overflow-x-0 {
	overflow-x: hidden;
}

.c-overflow-y-0 {
	overflow-y: hidden;
}

.c-opacity-90 {
	opacity: 90%;
}

.c-outline-0 {
	outline: none;
}

.c-hide-scrollbar {
	@include hide-scrollbar;
}

.c-border-radius-0 {
	border-radius: 0;
}

.c-pointer-events-none {
	pointer-events: none;
}

.c-cursor-def {
	cursor: default !important;
}

.c-cursor-pointer {
	cursor: pointer !important;
}

.c-cursor-none {
	cursor: none !important;
}

.c-rotate-y-180 {
	transform: rotateY(180deg);
}

.c-rotate-x-180 {
	transform: rotateX(180deg);
}

.c-rotate-z-180 {
	transform: rotateZ(180deg);
}

.c-transition-all {
	transition: all $animation-function $animation-times;
}

.c-l-1 {
	left: ($spacer * 0.25) !important;
}

.c-l-2 {
	left: ($spacer * 0.5) !important;
}

.c-l-3 {
	left: $spacer !important;
}

.c-r-1 {
	right: ($spacer * 0.25) !important;
}

.c-r-2 {
	right: ($spacer * 0.5) !important;
}

.c-r-3 {
	right: $spacer !important;
}

.c-r-4 {
	right: ($spacer * 1.25) !important;
}

.c-r-5 {
	right: ($spacer * 1.5) !important;
}

.c-r-6 {
	right: ($spacer * 1.75) !important;
}

.c-x-1 {
	right: ($spacer * 0.25) !important;
	left: ($spacer * 0.25) !important;
}

.c-x-2 {
	right: ($spacer * 0.5) !important;
	left: ($spacer * 0.5) !important;
}

.c-x-3 {
	right: $spacer !important;
	left: $spacer !important;
}

.c-x-4 {
	right: ($spacer * 1.25) !important;
	left: ($spacer * 1.25) !important;
}

.c-min-h-0 {
	min-height: 0;
}

.c-min-w-0 {
	min-width: 0;
}

.c-mw-25 {
	max-width: 25%;
}

.c-mw-50 {
	max-width: 50%;
}

.c-mw-75 {
	max-width: 75%;
}

.c-z-index-1-invert {
	z-index: -1;
}

.c-z-index-0 {
	z-index: 0;
}

.c-z-index-1 {
	z-index: 1;
}

.c-z-index-1-important {
	z-index: 1 !important;
}

.c-z-index-2 {
	z-index: 2;
}

.c-z-index-3 {
	z-index: 3;
}

.c-z-index-4 {
	z-index: 4;
}

.c-z-index-100 {
	z-index: 100;
}
.c-z-index-1000 {
	z-index: 1000;
}

.c-absolute-left {
	left: 0;
}

.c-absolute-right {
	right: 0;
}

.c-absolute-top {
	top: 0;
}

.c-absolute-bottom {
	bottom: 0;
}

.c-vertical-align-top {
	vertical-align: top;
}

.c-text-align-left {
	text-align: left;
}

.border-dot-start-bottom {
	position: relative;
}

.border-dot-start-bottom::after {
	content: '';
	position: absolute;
	display: block;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	bottom: 0;
	transform: translate(-55%, 50%);
}

.border-dot-primary::after {
	background: $primary;
}

@include media-breakpoint-up(sm) {
	.c-display-sm-0 {
		display: none;
	}
}

@include media-breakpoint-down(sm) {
	.c-display-sm {
		display: none;
	}
}

@include media-breakpoint-up(md) {
	.c-display-md-0 {
		display: none;
	}
}

@include media-breakpoint-down(md) {
	.c-display-md {
		display: none;
	}
}

@include media-breakpoint-up(lg) {
	.c-display-lg-0 {
		display: none;
	}

	.c-position-lg-absolute {
		position: absolute;
	}
}

@include media-breakpoint-down(lg) {
	.c-display-lg {
		display: none;
	}
}

@include media-breakpoint-up(xl) {
	.c-display-xl-0 {
		display: none;
	}
	.c-display-xl {
		display: initial !important;
	}
}

.c-display-xl {
	display: none;
}

@each $breakpoint, $size in $grid-breakpoints {
	@include media-breakpoint-up($breakpoint) {
		.c-#{$breakpoint}-border-left-only {
			border: 0;
			border-left: 1px solid $css-var-gray20 !important;
		}
	}
}

.c-transition-all {
	transition: all linear 0.2s;
}

.disabled-class {
	outline: none;
	box-shadow: none;
	border-color: #e9ecef !important;
	background: #e9ecef !important;
	color: $black !important;
}

//shadows
.c-shadow-start {
	box-shadow: -0.75rem 0px 0.5rem -0.75rem rgba($black, 0.15);
}

.c-shadow-end {
	box-shadow: 0.75rem 0 0.5rem -0.75rem rgba($black, 0.15);
}

.c-shadow-top {
	box-shadow: 0px -0.75rem 0.5rem -0.75rem rgba($black, 0.15);
}

.c-shadow-bottom {
	box-shadow: 0px 0.75rem 0.5rem -0.75rem rgba($black, 0.15);
}

.c-shadow-no-top {
	box-shadow: 0 0.5rem 0.5rem -0.1rem rgba($black, 0.15);
}

.c-text-truncate-2-lines {
	display: -webkit-box;
	line-clamp: 2;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}
