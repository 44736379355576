ngb-datepicker {
  border: none !important;
  border-radius: 0 !important;
  display: inline-block !important;
}

.ngb-dp-header {
  display: none !important;
}

.ngb-dp-weekdays {
  border-bottom: none !important;
  border-radius: 0 !important;
  background-color: transparent !important;
}

.ngb-dp-weekday {
  font-family: 'IBM Plex Sans';
  font-weight: bold;
  font-style: normal !important;
  color: var(--bs-primary) !important;
}
