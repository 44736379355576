@mixin c-distance-px {
	$px: 0;
	@for $i from 0 through 500 {
		.c-t-#{$px}px {
			top: #{$px}px;
		}

		.c-b-#{$px}px {
			bottom: #{$px}px;
		}

		.c-l-#{$px}px {
			left: #{$px}px;
		}

		.c-r-#{$px}px {
			right: #{$px}px;
		}

		$px: $px + 1;
	}
}

@include c-distance-px;

@mixin c-translate {
	@for $i from 0 through 100 {
		.c-translate-x-#{$i} {
			transform: translateX(#{$i + '%'});
		}

		.c-translate-y-#{$i} {
			transform: translateY(#{$i + '%'});
		}

		.c-translate-x-#{$i}-invert {
			transform: translateX(#{-$i + '%'});
		}

		.c-translate-y-#{$i}-invert {
			transform: translateY(#{-$i + '%'});
		}
	}
}

@include c-translate;
