.flash-animation {
	animation: 1s flashAnimation ease-in-out infinite;
}

@keyframes flashAnimation {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
