// With this we can get buttons to use the css property instead of a fixed value
$body-color-tmp: $body-color;
$body-color: $css-var-body-color;

$theme-colors-tmp: $theme-colors;
$theme-colors: ();

@import 'bootstrap/scss/buttons';

$body-color: $body-color-tmp;
$theme-colors: $theme-colors-tmp;

$box-shadow-bg: $css-var-gray40;

@mixin eva-btn-focused {
	.btn-check:focus + &,
	&:focus {
		@content;
	}
}

@mixin eva-btn-box-shadow-focus {
	@include eva-btn-focused {
		box-shadow: 1px 1px 5px $box-shadow-bg !important;
	}
}

@mixin eva-btn-box-shadow {
	transition: box-shadow $animation-function $animation-times;

	&:hover {
		box-shadow: 3px 3px 4px $box-shadow-bg;
	}

	@include eva-btn-box-shadow-focus;
}

@mixin eva-button-outline-variant($color, $contrast) {
	transition: box-shadow $animation-function $animation-times;

	--bs-btn-color: #{$color};
	--bs-btn-border-color: #{$color};
	--bs-btn-bg: #{$contrast};

	--bs-btn-hover-color: #{$contrast};
	--bs-btn-hover-border-color: #{$color};
	--bs-btn-hover-bg: #{$color};

	--bs-btn-active-color: #{$contrast};
	--bs-btn-active-border-color: #{$color};
	--bs-btn-active-bg: #{$color};

	--bs-btn-disabled-color: #{$color};
	--bs-btn-disabled-border-color: #{$color};
	--bs-btn-disabled-bg: transparent;

	@include eva-btn-box-shadow-focus;
}

/***** primary button *****/
.btn-primary {
	@include button-variant(
		$background: $css-var-primary,
		$border: $primary,
		$color: color-contrast($primary),
		$hover-background: $btn-hover-bg-primary,
		$hover-border: $btn-hover-border-primary,
		$hover-color: $btn-hover-color-primary,
		$active-background: $btn-active-bg-primary,
		$active-border: $btn-active-border-primary,
		$active-color: $btn-active-color-primary,
		$disabled-color: $primary-contrast-color,
		$disabled-border: $css-var-primary
	);

	color: $primary-contrast-color;
	border-color: $css-var-primary;

	@include eva-btn-box-shadow;
}

.btn-outline-primary {
	@include eva-button-outline-variant($css-var-primary, $primary-contrast-color);
}

.btn-secondary {
	@include button-variant(
		$background: $css-var-secondary,
		$border: $secondary,
		$color: color-contrast($secondary),
		$hover-background: var(--eva-btn-hover-bg-secondary, #3b7070),
		$hover-border: var(--eva-btn-hover-border-secondary, #386969),
		$hover-color: var(--eva-btn-hover-bg-secondary-contrast, #ffffff),
		$active-background: var(--eva-btn-active-bg-secondary, #386969),
		$active-border: var(--eva-btn-active-border-secondary, #346363),
		$active-color: var(--eva-btn-active-bg-secondary-contrast, #ffffff),
		$disabled-color: $secondary-contrast-color,
		$disabled-border: $css-var-secondary
	);

	color: $secondary-contrast-color;
	border-color: $css-var-secondary;

	@include eva-btn-box-shadow;
}

.btn-outline-secondary {
	@include eva-button-outline-variant($css-var-secondary, $secondary-contrast-color);
}

.btn-danger {
	@include button-variant($danger, $danger);
	@include eva-btn-box-shadow;
}

.btn-outline-danger {
	@include button-outline-variant($danger);
}

/***** link button *****/
.btn-link:not(:disabled):hover {
	background: $css-var-light;
}

.btn-outline-link {
	@extend .btn-link;
	--bs-btn-border-color: #{$css-var-gray20};
	--bs-btn-hover-border-color: #{$css-var-gray20};
	--bs-btn-active-border-color: #{$btn-link-hover-color};
	--bs-btn-disabled-border-color: #{$css-var-gray20};
}
