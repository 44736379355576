@import 'bootstrap/scss/badge';

.badge {
	&.bg-primary {
		color: $primary-contrast-color;
	}

	&.bg-secondary {
		color: $secondary-contrast-color;
	}
}
