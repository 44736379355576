.c-w-unset {
	width: unset !important;
}

.c-w-min-content {
	width: min-content;
}

.c-w-500px {
	width: 500px;
}

.c-w-300px {
	width: 300px;
}

@include media-breakpoint-up(lg) {
	.c-h-lg-100 {
		height: 100%;
	}
}

.c-h-4em {
	height: 4em;
}

@mixin c-size-px {
	$px: 0;
	@for $i from 0 through 150 {
		.c-w-#{$px}px {
			width: #{$px}px;
		}

		.c-h-#{$px}px {
			height: #{$px}px;
		}

		.c-mw-#{$px}px {
			min-width: #{$px}px;
		}

		.c-mh-#{$px}px {
			min-height: #{$px}px;
		}

		.c-w-#{$px}px-i {
			width: #{$px}px !important;
		}

		.c-h-100-#{$px}px {
			height: calc(100% - #{$px}px);
		}

		.c-h-#{$px}px-i {
			height: #{$px}px !important;
		}

		.c-mw-#{$px}px-i {
			min-width: #{$px}px !important;
		}

		.c-mh-#{$px}px-i {
			min-height: #{$px}px !important;
		}

		@include media-breakpoint-up(sm) {
			.c-w-sm-#{$px}px {
				width: #{$px}px;
			}

			.c-h-sm-#{$px}px {
				height: #{$px}px;
			}
		}

		@include media-breakpoint-up(md) {
			.c-w-md-#{$px}px {
				width: #{$px}px;
			}

			.c-h-md-#{$px}px {
				height: #{$px}px;
			}
		}

		@include media-breakpoint-up(lg) {
			.c-w-lg-#{$px}px {
				width: #{$px}px;
			}

			.c-h-lg-#{$px}px {
				height: #{$px}px;
			}
		}

		@include media-breakpoint-up(xl) {
			.c-w-xl-#{$px}px {
				width: #{$px}px;
			}

			.c-h-xl-#{$px}px {
				height: #{$px}px;
			}
		}

		@include media-breakpoint-down(sm) {
			.c-w-sm-max-#{$px}px {
				width: #{$px}px;
			}

			.c-h-sm-max-#{$px}px {
				height: #{$px}px;
			}

			.c-h-max-100-#{$px}px {
				height: calc(100% - #{$px}px);
			}
		}

		@include media-breakpoint-down(md) {
			.c-w-md-max-#{$px}px {
				width: #{$px}px;
			}

			.c-h-md-max-#{$px}px {
				height: #{$px}px;
			}
		}

		@include media-breakpoint-down(lg) {
			.c-w-lg-max-#{$px}px {
				width: #{$px}px;
			}

			.c-h-lg-max-#{$px}px {
				height: #{$px}px;
			}
		}

		@include media-breakpoint-down(xl) {
			.c-w-xl-max-#{$px}px {
				width: #{$px}px;
			}

			.c-h-xl-max-#{$px}px {
				height: #{$px}px;
			}
		}

		$px: $px + 1;
	}
}

@include c-size-px;

.c-mh-100 {
	min-height: 100%;
}

@each $breakpoint in (sm, md, lg, xl) {
	@include media-breakpoint-up($breakpoint) {
		@each $name, $size in $font-sizes {
			.c-h#{$name}-#{$breakpoint} {
				font-size: $size;
			}
		}
	}
}
