$topmenu-bg: var(--eva-topmenu-bg, $white); // bgcolor9
$topmenu-logo-bg: var(--eva-topmenu-logo-bg); // bgcolor10
$topmenu-item-color: var(--eva-topmenu-item-color, $gray70); // color6
$topmenu-item-active-color: var(--eva-topmenu-item-active-color, $primary); // bgcolor5

$sidebar-bg: var(--eva-sidebar-bg, $white); // bgcolor1
$sidemenu-item-color: var(--eva-sidemenu-item-color, $gray-800); // bgcolor8
$sidemenu-item-hover-color: var(--eva-sidemenu-item-hover-color, $primary); // bgcolor4
$sidemenu-heading-bg: var(--eva-sidemenu-heading-bg); // bgcolor3
$sidemenu-heading-color: var(--eva-sidemenu-heading-color); // color1
$sidemenu-item-active-bg: var(--eva-sidemenu-item-active-bg, $light); // bgcolor7
$sidemenu-item-active-color: var(--eva-sidemenu-item-active-color, $primary); // bgcolor6

$css-var-body-bg: var(--bs-body-bg, $body-bg); // bgcolor2
$css-var-body-color: var(--bs-body-color, $body-color); // color2
$css-var-body-color-rgb: var(--bs-body-color-rgb, to-rgb($body-color));

$css-var-primary: var(--bs-primary, $primary); // color_primary
$css-var-secondary: var(--bs-secondary, $secondary); // color_secondary
$css-var-light: var(--bs-light, $light); // color_light

$css-var-link-color: var(--eva-link-color, $link-color); // color3
$css-var-link-hover-color: var(--eva-link-hover-color, $link-hover-color); // color5
$css-var-text-muted: var(--eva-hint-color, $text-muted); // color4

$table-row-bg-odd: var(--eva-table-row-bg-odd); // tabcolor1
$table-row-bg-even: var(--eva-table-row-bg-even); // tabcolor2
$table-header-bg: var(--eva-table-header-bg); // tabcolor3
$table-header-color: var(--eva-table-header-color, $body-color); // tabcolor4
$table-row-color: var(--eva-table-row-color); // tabcolor5
$table-border-color: var(--eva-table-border-color, $gray20); // color_table_border

$css-var-card-bg: var(--eva-card-bg, $card-bg); // color_card_background
$css-var-card-color: var(--eva-card-color); // color_card_font

// region gray

$css-var-gray2: var(--eva-gray2, $gray2);
$css-var-gray20: var(--eva-gray20, $gray20);
$css-var-gray40: var(--eva-gray40, $gray40);
$css-var-gray70: var(--eva-gray70, $gray70);
$css-var-gray75: var(--eva-gray75, $gray75);
$css-var-gray80: var(--eva-gray80, $gray80);
$css-var-gray85: var(--eva-gray85, $gray85);
$css-var-gray90: var(--eva-gray90, $gray90);

$css-var-black: var(--bs-black, $black);

// endregion gray

// region generated colors
$primary-contrast-color: var(--eva-primary-contrast-color, $white);
$secondary-contrast-color: var(--eva-secondary-contrast-color, $white);
$light-contrast-color: var(--eva-light-contrast-color, $black);

// region specialized

$css-var-dropdown-link-hover-color: var(--eva-dropdown-link-hover-color, $dropdown-link-hover-color);

$btn-hover-bg-primary: var(--eva-btn-hover-bg-primary, #084d4d);
$btn-hover-border-primary: var(--eva-btn-hover-border-primary, #084848);
$btn-hover-color-primary: var(--eva-btn-hover-bg-primary-contrast, #ffffff);

$btn-active-bg-primary: var(--eva-btn-active-bg-primary, #084848);
$btn-active-border-primary: var(--eva-btn-active-border-primary, #074444);
$btn-active-color-primary: var(--eva-btn-active-bg-primary-contrast, #ffffff);

// endregion specialized

// endregion generated colors
