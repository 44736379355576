@import 'bootstrap/scss/root';

:root {
	--eva-floating-label-bg: #{$topmenu-bg};
	--eva-floating-label-color: #{$css-var-body-color};

	--eva-dropdown-bg: #{$css-var-body-bg};
	--eva-dropdown-color: #{$css-var-body-color};

	--bs-btn-active-border-color: transparent;

	--bs-popover-bg: #{$css-var-light};
}
