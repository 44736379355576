@import 'bootstrap/scss/dropdown';

.dropdown-toggle {
	background: none !important;
	outline: none;
	box-shadow: none;
	width: 100%;
	min-height: 38px;
	text-align: left;
}

.show > .btn-outline-primary.dropdown-toggle {
	border-color: $css-var-primary;

	.dropdown-label:not(.dropdown-label-inside) {
		color: $css-var-primary;
	}
}

.dropdown-label {
	transition: all $animation-function $animation-times;

	&.dropdown-label-inside {
		font-size: $dropdown-font-size;
	}

	&:not(.dropdown-label-inside) {
		position: absolute;
		font-size: $h6-font-size;
		background: $dropdown-bg;
		color: $dropdown-color;
	}
}

.dropdown-toggle:after {
	position: absolute;
}

.dropdown .form-control {
	box-shadow: none !important;
}

.dropdown-item {
	box-shadow: none;
	outline: none;
	transition: all $animation-function $animation-times;
	width: auto;

	&:active,
	&.active {
		background: $dropdown-link-hover-bg;
		outline: none;
		color: $dropdown-link-hover-color !important;
	}

	&:hover {
		background: $dropdown-link-hover-bg;
		color: $dropdown-link-hover-color;
	}
}

.dropdown-value {
	font-size: $dropdown-font-size;
}

.dropdown.is-invalid .dropdown-toggle:disabled ~ .invalid-feedback {
	display: none !important;
}

.dropdown {
	outline: none !important;
	box-shadow: none;

	& > .btn {
		color: $floating-label-color;
		border-radius: $input-border-radius;
		border-color: $input-border-color;
	}

	&.is-focused > .btn,
	&.show:not(.is-invalid) > .btn {
		outline: none;
		border-color: $css-var-primary;
		box-shadow: $control-box-shadow-size $css-var-primary;
	}

	&.is-invalid > .btn {
		border-color: $red;
		box-shadow: $control-box-shadow-size $red;

		.dropdown-label {
			color: $red;
		}
	}
}

.dropdown-label,
.form-label-wrapper {
	&:not(.form-label-inside) {
		@mixin focused {
			color: $css-var-primary;
		}

		@at-root .dropdown.is-focused & {
			@include focused;
		}

		@at-root .dropdown.show:not(.is-invalid) & {
			@include focused;
		}

		@at-root .dropdown.is-invalid & {
			color: $red;
		}
	}
}

:not(ngb-datepicker).dropdown-menu {
	max-width: calc(min(50rem, 100vw - 2.5rem)) !important;
	min-width: 100%;
	overflow-y: auto;
}
