@import 'bootstrap/scss/popover';

.popover {
	min-width: 140px;
}
.popover.popover-sm {
	max-width: 300px;
}
.popover.popover-md {
	max-width: 500px;
}
.popover.popover-lg {
	max-width: 700px;
}
.popover.popover-100-padded {
	min-width: calc(100% - 3rem);
	max-width: calc(100% - 3rem);
}
.popover.popover-w-lg {
	width: 700px;
}
.popover.popover-z-index-1500 {
	z-index: 1500;
}

.popover-padding-0 .popover-body {
	padding: 0;
}
