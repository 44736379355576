@import '../../../node_modules/bootstrap/scss/list-group';

.list-group-item {
	border: 0;
	border-bottom: 1px solid $css-var-gray20;
	border-radius: 0;
	transition: all $animation-function $animation-times;
}

.list-group-item:not(.active) {
	color: $css-var-gray70;
}

.list-group-item.active {
	background: $white;
	color: $css-var-primary;
	border-radius: 0;
	margin-top: 0;
	font-weight: bold;
}

.list-group-link {
	cursor: pointer;
}
