.typeahead-popover .popover-body {
	border-radius: var(--bs-popover-inner-border-radius);
	padding: 0;
	background: $css-var-dropdown-bg;
}

.typeahead-dropdown-menu {
	--bs-dropdown-link-active-bg: #{$dropdown-link-hover-bg};
	--bs-dropdown-link-active-color: #{$dropdown-link-hover-color};
}
