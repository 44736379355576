// Here we replace static values with css variables

/***** Popover *****/
$popover-bg: $css-var-light;
$popover-border-width: 0px;

// Links
$link-color: $css-var-link-color;
$link-hover-color: $css-var-link-hover-color;

// region Forms

$input-color: $css-var-body-color;
$input-border-color: $css-var-gray40;
$input-focus-color: $input-color;

$input-group-addon-color: $input-color;
$input-group-addon-bg: $css-var-gray40;
$input-group-addon-border-color: $input-border-color;

$form-check-input-checked-bg-color: $css-var-primary;
$form-check-input-checked-border-color: $css-var-primary;

$form-select-color: $input-color;
$form-select-border-color: $input-border-color;
$form-file-button-color: $input-color;

$form-text-color: $input-color;

$dropdown-bg: $css-var-dropdown-bg;
$dropdown-color: $css-var-dropdown-color;

$dropdown-link-color: $input-color;
$dropdown-link-hover-bg: $css-var-light;
$dropdown-link-hover-color: $css-var-dropdown-link-hover-color;
$dropdown-divider-bg: $table-border-color;

// endregion

// region buttons

$btn-link-color: $css-var-secondary;
$btn-link-hover-color: $btn-link-color;
$btn-link-disabled-color: $css-var-gray40;

// endregion buttons

// region Tables

$table-hover-bg: $css-var-gray40;

// endregion Tables

// region _tooltip.scss

$tooltip-color: $css-var-body-color;
$tooltip-bg: $topmenu-bg;
$tooltip-arrow-color: $topmenu-bg;

// endregion _tooltip.scss

// Cards
$card-bg: $css-var-card-bg;
$card-color: $css-var-card-color;

// Modals
$modal-content-bg: $topmenu-bg;

$offcanvas-bg-color: $modal-content-bg;
