@import 'bootstrap/scss/tables';

.table {
	color: $table-row-color;

	> thead {
		background-color: $table-header-bg;
	}

	> tr,
	> tbody > tr {
		&:nth-child(2n) {
			background-color: $table-row-bg-odd;
		}

		&:not(:nth-child(2n)) {
			background-color: $table-row-bg-even;
		}
	}

	> tr > th,
	> thead > tr > th {
		font-weight: $font-weight-normal;
	}

	> tr > th,
	> * > tr > th {
		color: $table-header-color;
	}
}
