@import '../../../node_modules/bootstrap/scss/breadcrumb';

.breadcrumb {
	background: none;
}

.breadcrumb a.breadcrumb-item {
	cursor: pointer;
}

.breadcrumb .breadcrumb-item.active {
	color: black;
	font-weight: bold;
}
