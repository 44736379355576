// Just imports in this file

// Required bootstrap components & customized variables
@import 'variables';
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/mixins';
@import '../../shared/bootstrap/root';

//TODO: Customizings im besten Fall komplett auf Bootstrap-Variables umstellen: https://getbootstrap.com/docs/5.0/components/accordion/ => Seitenende
/***** customized *****/
@import '../../shared/bootstrap/tables';
@import '../../shared/bootstrap/reboot';
@import '../../shared/bootstrap/buttons';
@import '../../shared/bootstrap/nav';
@import '../../shared/bootstrap/forms';
@import '../../shared/bootstrap/dropdown';
@import '../../shared/bootstrap/typeahead';
@import '../../shared/bootstrap/list-group';
@import '../../shared/bootstrap/type';
@import '../../shared/bootstrap/pagination';
@import '../../shared/bootstrap/alert';
@import '../../shared/bootstrap/custom/custom';
@import '../../shared/bootstrap/custom/menu';
@import '../../shared/bootstrap/popover';
@import '../../shared/bootstrap/card';
@import '../../shared/bootstrap/modal';
@import '../../shared/bootstrap/breadcrumb';
@import '../../shared/bootstrap/input-group';
@import '../../shared/bootstrap/extends';
@import '../../shared/bootstrap/tooltip';
@import '../../shared/bootstrap/spaces';
@import '../../shared/bootstrap/badge';
@import '../../shared/bootstrap/utilities';
@import '../../shared/bootstrap/accordion';
@import '../../shared/bootstrap/floating-label';

/***** non customized *****/
@import 'bootstrap/scss/helpers';
@import 'bootstrap/scss/images';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/close';
@import 'bootstrap/scss/navbar';
@import 'bootstrap/scss/transitions';
@import 'bootstrap/scss/containers';
@import 'bootstrap/scss/progress';
@import 'bootstrap/scss/spinners';
@import 'bootstrap/scss/offcanvas';

@import 'bootstrap/scss/utilities/api';

/***** custom components *****/
@import 'document-360';
